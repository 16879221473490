import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { Firestore, connectFirestoreEmulator, initializeFirestore, getFirestore } from 'firebase/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore } from '@angular/fire/firestore';



bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    
      provideFirebaseApp(() => {
        const app = initializeApp(environment.firebaseConfig)
        return app
      }),
    provideFirestore(() => {
      const app = initializeApp(environment.firebaseConfig)
      let firestore: Firestore;
      firestore = getFirestore();
      
      return firestore
    })
  ],
});


