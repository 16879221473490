import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'magic-formula',
    loadComponent: () => import('./magic-formula/magic-formula.page').then( m => m.MagicFormulaPage)
  },
  {
    path: 'quiz-screen',
    loadComponent: () => import('./quiz-screen/quiz-screen.page').then( m => m.QuizScreenPage)
  },
  {
    path: 'quiz-player',
    loadComponent: () => import('./quiz-player/quiz-player.page').then( m => m.QuizPlayerPage)
  },
  {
    path: 'quiz-admin',
    loadComponent: () => import('./quiz-admin/quiz-admin.page').then( m => m.QuizAdminPage)
  },
];
